import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import img from "../assets/images/about/equipoEnAccion.png";
import Team from "../components/layouts/Team";

const AboutUs = () => {
  return (
    <div className="about">
      <Header />
      <section className="tf-section page-title">
        <div className="container">
          <div className="col-md-12">
            <div className="page-title__body ab">
              <div className="block-text pt-12">
                <h2 className="sub-title mb-20">Sobre Nosotros</h2>
                <p className="fs-24 mb-33">
                  Contamos con un staff de abogados de nivel seleccionado y
                  probada seriedad, dedicado totalmente a buscar soluciones en
                  los asuntos que nos confían nuestros clientes.
                </p>
              </div>
              <img src={img} alt="nosotros" />
            </div>
          </div>
        </div>
      </section>
      <Team />
      <Footer />
    </div>
  );
};

export default AboutUs;
