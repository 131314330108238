import React from 'react';
import { Link } from 'react-router-dom'
import dataTeam from '../../assets/fake-data/data-team';

const Team = () => {
    return (
        <div className='page-team'>         
            <section className="tf-section team">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>MGB</span></h1>
                                <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Nuestro Equipo</h5>
                                <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">Conoce a Nuestro Equipo <br />de Expertos</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-107">
                        {
                            dataTeam.map((data,index)=> (
                                <div key={index} className="col-xl-4 col-md-6">
                                    <div className="team-box">
                                        <div className="img-team">
                                            <img src={data.img} alt="Monteno" />
                                            <div className="social">
                                                <p>
                                                    <Link to="#"><i className="fab fa-facebook-square"></i></Link>
                                                    <Link to="#"><i className="fab fa-twitter-square"></i></Link>
                                                    <Link to="#"><i className="fab fa-telegram"></i></Link>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="team-info">
                                            <Link to="#" className="h5">{data.name}</Link>
                                            <p className="fs-16">{data.position}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        
                    </div>
                </div>
            </section>           
        </div>
    );
}

export default Team;