import img1 from '../images/team/joseMariaMolina.png'
import img2 from '../images/team/ernestoGarciaBiagosch.png'
import img3 from '../images/team/ileanaAntoniellaBataglia.png'
import img4 from '../images/team/agustinaLasquera.png'
import img5 from '../images/team/belloJuanPablo.png'
import img6 from '../images/team/patricioFresia.png'


const dataTeam = [
    {
        img: img1,
        name:'Jose Maria Molina',
        position: 'ABOGADO (SOCIO FUNDADOR)'
    },
    {
        img: img2,
        name:'Ernesto García Biagosch',
        position: 'ABOGADO (SOCIO FUNDADOR)'
    },
    {
        img: img3,
        name:'Ileana Antoniella Bataglia',
        position: 'ABOGADA (SOCIA)'
    },
    {
        img: img4,
        name:'Agustina Lasquera',
        position: 'ABOGADA'
    },
    {
        img: img5,
        name:'Juan Pablo Bello',
        position: 'ABOGADO'
    },
    {
        img: img6,
        name:'Patricio Fresia',
        position: 'ABOGADO'
    }
    
]

export default dataTeam;