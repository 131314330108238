import img1 from "../images/blog/1.png";
import img2 from "../images/blog/2.png";
import img3 from "../images/blog/3.png";
import img4 from "../images/blog/4.png";
import img5 from "../images/blog/5.png";
import img6 from "../images/blog/6.png";
import img7 from "../images/blog/7.png";
import img8 from "../images/blog/8.png";
import img9 from "../images/blog/9.png";
import img10 from "../images/blog/10.png";
import img11 from "../images/blog/11.png";
import img12 from "../images/blog/12.png";
import img13 from "../images/blog/13.png";
import img14 from "../images/blog/14.png";

const dataBlog = [
  {
    img: img14,
    category:
      "Futbolistas de Vélez: la joven que denunció abuso sexual se niega a entregar su celular",
    title:
      "La denunciante teme que no se respete su intimidad. Ella y una testigo tendrán tiempo para poner a disposición sus teléfonos hasta el martes y hoy, respectivamente. De lo contrario serán secuestrados.",
    link: "https://www.lagaceta.com.ar/nota/1027127/seguridad/nuevos-indicios-complican-futbolistas-velez.html",
  },
  {
    img: img13,
    category:
      "Día clave para los futbolistas de Vélez denunciados por abuso en Tucumán",
    title:
      "Hoy la víctima es entrevistada en una Cámara Gesell. Los defensores plantean algunas dudas sobre los dichos de la joven.",
    link: "https://www.lagaceta.com.ar/nota/1027807/seguridad/sera-dia-clave-para-futbolistas-velez-denunciados-abuso.html",
  },
  {
    img: img10,
    category:
      "Abuso sexual en Tucumán: los jugadores de Vélez quieren contar su verdad",
    title:
      "Los defensores de los futbolistas buscan demostrar su inocencia. Nuevos detalles. Más pericias.",
    link: "https://www.lagaceta.com.ar/nota/1027525/seguridad/futbolistas-velez-ellos-lo-unico-quieren-contar-verdad.html",
  },
  {
    img: img12,
    category:
      "Los chats reveladores en la denuncia por abuso contra los jugadores de Vélez",
    title:
      "Se filtraron conversaciones entre la denunciante y Sebastián Sosa. El lunes se hará la Cámara Gesell a la víctima, los futbolistas podrían quedar detenidos preventivamente.",
    link: "https://www.lagaceta.com.ar/nota/1027127/seguridad/nuevos-indicios-complican-futbolistas-velez.html",
  },
  {
    img: img11,
    category:
      "Denuncia por abuso sexual: tres futbolistas de Vélez están más complicados y podrían pedir su detención",
    title:
      "Lo determinó la fiscal a cargo de la investigación por la presunta violación de una periodista de 24 años.",
    link: "https://www.lagaceta.com.ar/nota/1027126/seguridad/condenaron-al-joven-choco-mato-taxista.html",
  },
  {
    img: img10,
    category: "Nuevos indicios complican a los futbolistas de Vélez",
    title:
      "La fiscal se reunió ayer con los defensores José María Molina (asiste a Osorio y a Cufré), Ernesto Baaclini (Sosa) y Camilo Atim y María Florencia Abdala (Florentín Bobadilla) y la querellante Patricia Neme.",
    link: "https://www.lagaceta.com.ar/nota/1027127/seguridad/nuevos-indicios-complican-futbolistas-velez.html",
  },
  {
    img: img9,
    category: "Condenaron al joven que chocó y mató a un taxista",
    title:
      "Mediante un juicio abreviado Gonzalo Nicolás González fue sentenciado a tres años de prisióncondicional y nueve de inhabilitación para conducir. Ofreció una reparación de $90 millones.",
    link: "https://www.lagaceta.com.ar/nota/1027126/seguridad/condenaron-al-joven-choco-mato-taxista.html",
  },
  {
    img: img8,
    category: "Hay más condenas por violencia de género que por robos",
    title:
      "Especialistas consideran que la persecución penal no alcanza porque siempre llega tarde. Estadísticas que sorprenden. Planteos.",
    link: "https://www.lagaceta.com.ar/nota/1026668/seguridad/hay-mas-condenas-violencia-genero-robos.html",
  },
  {
    img: img7,
    category: "Agustina Lasquera habló sobre la crisis carcelaría",
    title:
      "Esta es una falla porque la regla debe ser la libertad del procesado mientras se resuelve acerca de su responsabilidad penal en un debate oral y público o bien con una condena a través de un juicio abreviado.",
    link: "https://www.lagaceta.com.ar/nota/999577/seguridad/algunas-razones-se-agravo-crisis-carcelaria-tucuman.html",
  },
  {
    img: img6,
    category: "Crimen de Chacabuco 59: Fumero no habló y terminó complicándose",
    title:
      "El ex marido de Picciuto seguirá detenido. Otros tres acusados seguirán con prisión preventiva, pero con la modalidad de arresto domiciliario.",
    link: "https://www.lagaceta.com.ar/nota/995319/seguridad/crimen-chacabuco-59-fumero-no-hablo-termino-complicandose.html",
  },
  {
    img: img5,
    category: "Crimen de Chacabuco 59.",
    title:
      'Esta mañana, Socci y Liberman, junto a su abogado, José María Molina, hablaron en exclusiva con LG Play para contar su versión. Consideraron "injusta e indiscriminada la investigación sobre el hallazgo del cadáver en calle Chacabuco 59".',
    link: "https://www.lagaceta.com.ar/nota/993291/seguridad/hora-definiciones-caso-crimen-chacabuco-59.html ",
  },
  {
    img: img4,
    category: "Crimen de Chacabuco 59: Hora de definicionos.",
    title:
      "Un detenido, y los dos inquilinos, podrían ser acusados de homicidio en una audiencia que se desarrollará hoy. ¿Cuales son los indicios que hay en su contra?.",
    link: "https://www.lagaceta.com.ar/nota/993291/seguridad/hora-definiciones-caso-crimen-chacabuco-59.html",
  },
  {
    img: img3,
    category: "Caso Mohamed: Las pruebas contra el acusado.",
    title:
      "Declaran los peritos que intervinieron en el caso. Las cámaras habrían captado a Luis Carlos Valdez tras el hecho; una piedra sería el arma homicida.",
    link: "https://www.lagaceta.com.ar/nota/988039/seguridad/caso-mohamed-pruebas-contra-acusado.html?utm_source=Whatsapp&utm_medium=Social&utm_campaign=botonmovil",
  },
  {
    img: img2,
    category:
      "Crimen de Manuel Mohamed: Un perito reconoció la piedra con la que fue asesinado.",
    title:
      "Aportaron su testimonio diferentes expertos del Equipo Científico de Investigaciones Fiscales.",
    link: "https://www.lagaceta.com.ar/nota/987982/seguridad/crimen-manuel-mohamed-perito-reconocio-piedra-fue-asesinado.html?utm_source=Whatsapp&utm_medium=Social&utm_campaign=botonmovil",
  },
  {
    img: img1,
    category:
      "Crimen de Manuel Mohamed: la versión del acusado no encuentra sustento.",
    title:
      "Luis Carlos Valdez hizo graves acusaciones contra la víctima, pero hasta el momento no pudo probar sus dichos. Los peritos rebaten su teoría.",
    link: "https://www.lagaceta.com.ar/nota/988039/seguridad/caso-mohamed-pruebas-contra-acusado.html?utm_source=Whatsapp&utm_medium=Social&utm_campaign=botonmovil",
  },
];

export default dataBlog;
