import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Servicios1 from "../components/layouts/Servicios1";
import Slider from "../components/slider/Slider";
import Blog from "../components/layouts/Blog";
import dataBlog from "../assets/fake-data/data-blog";
import Action from "../components/layouts/Action";
import Videos from "../components/layouts/Videos";

const Home = () => {
  return (
    <div className="home-3">
      <Header />
      <Slider />
      <Servicios1 />
      <Videos />
      <Blog data={dataBlog} />
      <Action />
      <Footer />
    </div>
  );
};

export default Home;
