import React from "react";
import temporal from "../../assets/images/pages/temporal.png";
import { Link } from "react-router-dom";

const Servicios = () => {
  return (
    <div className="service">
      <section className="tf-section visions service1">
        <div className="container">
          <div className="row reverse">
            <div className="col-xl-7 col-md-12">
              <div
                className="group-image"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <img src={temporal} alt="temporal" />
              </div>
            </div>
            <div className="col-xl-5 col-md-12">
              <div className="block-text2 border2 pt-12">
                <h5
                  className="sub-title mb-10"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Nuestros Servicios
                </h5>
                <h3
                  className="title mb-37"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  MGB Abogados Penalistas y Asociados
                </h3>
                <p
                  className="fs-18 mb-41"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Nos caracteriza, la atención directa con cada uno de los
                  clientes, así como la celeridad y practicidad en las
                  resoluciones de casos que nos llegan. Comprendemos la
                  complejidad que conlleva tener un conflicto con la ley penal
                  como así también la de ser víctima de un delito. Por eso, nos
                  dedicamos especialmente a cada uno de nuestros clientes para
                  darle la mejor atención y lo más rápido posible...
                </p>
                <Link
                  to="/service"
                  className="btn-action style-2 "
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  Saber Más...
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Servicios;
