import Home from './Home';
import AboutUs from './AboutUs';
import Service from './Service';
import Contact from './Contact';

const routes = [ 

  { path: '/', component: <Home />},
  { path: '/about', component: <AboutUs />},   
  { path: '/service', component: <Service />},
  { path: '/contact', component: <Contact />},
]

export default routes;