import React from "react";
import ecommercePC from "../../assets/images/pages/temporal3.png";

const Servicios2 = () => {
  return (
    <div className="service">
      <section className="tf-section visions">
        <div className="container">
          <div className="row reverse">
            <div className="col-xl-6 col-md-12">
              <div className="block-text left pt-12">
                <h5
                  className="sub-title mb-10"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  ¿Que hacemos?
                </h5>
                <h3
                  className="title mb-37"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Derecho Penal{" "}
                </h3>
                <p
                  className="fs-18 mb-20"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Brindamos servicios jurídicos en cualquiera de las instancias
                  de un proceso penal, tanto en la investigación penal
                  preparatoria o procedimiento de instrucción, como en la
                  acusación o defensa en juicio oral. Asimismo, en la
                  realización de recursos ordinarios ante los tribunales de
                  impugnación o extraordinarios ante los superiores tribunales
                  de la provincia o ante la Corte Suprema de Justicia de la
                  Nación.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-12">
              <div
                className="group-image s4"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <img src={ecommercePC} alt="eccomercePC" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Servicios2;
