import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Action from "../components/layouts/Action";
import img from "../assets/images/items/servicio.png";
import Servicios2 from "../components/layouts/Servicios2";
import Servicios3 from "../components/layouts/Servicios3";

const Servicios = () => {
  return (
    <div className="service">
      <Header />
      <section className="tf-section page-title">
        <div className="container">
          <div className="col-md-12">
            <div className="page-title__body2 rm">
              <div className="block-text4 pt-12">
                <h2 className="sub-title mb-20">Servicios</h2>
                <p className="fs-24 mb-33">
                  MGB & ASOCIADOS se dedica de forma exclusiva al derecho penal,
                  asistiendo a personas físicas y jurídicas, querellantes e
                  imputados (mayores y menores de edad). Trabajamos en toda la
                  Provincia de Tucumán y, en el fuero federal, en todo el país.{" "}
                </p>
              </div>
              <img className="s2" src={img} alt="WebTotal" />
            </div>
          </div>
        </div>
      </section>
      <Servicios2 />
      <Servicios3 />
      <Action />
      <Footer />
    </div>
  );
};

export default Servicios;
