import React from "react";

const Servicios3 = () => {
  return (
    <div className="service3">
      <section className="tf-section visions">
        <div className="container">
          <div className="row reverse up extra-large ">
            <div className="col-xl-12 col-md-12">
              <div className="block-text  pt-12">
                <h5
                  className="sub-title mb-10"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Nuestro Alcance
                </h5>
                <h3
                  className="title mb-37"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Servicios
                </h3>
                <p
                  className="fs-18 mb-20"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Nuestros servicios abarcan todos los centros judiciales de la
                  Provincia de Tucumán (Capital, Concepción y Monteros). Como
                  así también todos los tribunales federales de la República
                  Argentina.
                </p>
                <p
                  className="fs-18 mb-20"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  El servicio profesional del estudio jurídico abarca cualquiera
                  de los siguientes aspectos:
                </p>
                <p
                  className="fs-18 mb-20"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <b>
                    Defensa técnica penal en Etapa de Investigación Penal
                    Preparatoria y de Juicio Oral.
                  </b>{" "}
                </p>
                <ul data-aos="fade-up" data-aos-duration="1000">
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Asistencia en audiencia de aprehensión, formulación de
                      cargos y medidas de coerción{" "}
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Asistencia en audiencia de aplicación de criterio de
                      oportunidad (suspensión de juicio a prueba -probation-,
                      juicio abreviado, etc.)
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Asistencia en audiencia de control de acusación y
                      admisibilidad de la prueba
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Asistencia en audiencia de debate oral y publico
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Asistencia en audiencias de Régimen Penal Juvenil{" "}
                    </p>
                  </li>
                </ul>
                <p
                  className="fs-18 mb-20"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <b>Defensa técnica penal en Etapa Recursiva.</b>{" "}
                </p>
                <ul data-aos="fade-up" data-aos-duration="1000">
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">Recursos ordinarios</p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">Recursos extraordinarios</p>
                  </li>
                </ul>
                <p
                  className="fs-18 mb-20"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <b>Defensa técnica en Etapa de Ejecución de la Pena.</b>{" "}
                </p>
                <ul data-aos="fade-up" data-aos-duration="1000">
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Asistencia en Audiencias de ejecución
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Solicitud de traslados (médicos, comisaria, etc.)
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Solicitud de beneficios (Salidas transitorias, Prisión
                      domiciliaria, Libertad condicional, etc.)
                    </p>
                  </li>
                </ul>
                <p
                  className="fs-18 mb-20"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <b>Habeas Corpus</b>{" "}
                </p>
                <ul data-aos="fade-up" data-aos-duration="1000">
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Solicitud de habeas corpus preventivo
                    </p>
                  </li>
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Solicitud de habeas corpus correctivo
                    </p>
                  </li>
                </ul>
                <p
                  className="fs-18 mb-20"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <b>Querellas y Actor Civil</b>{" "}
                </p>
                <ul data-aos="fade-up" data-aos-duration="1000">
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Asistencia del particular damnificado y actor civil, en
                      todas las instancias del proceso.{" "}
                    </p>
                  </li>
                </ul>
                <p
                  className="fs-18 mb-20"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <b>Peritajes de parte</b>{" "}
                </p>
                <ul data-aos="fade-up" data-aos-duration="1000">
                  <li>
                    <i className="fa fa-check"></i>
                    <p className="fs-18">
                      Contamos con peritos asociados en diversas ramas:
                      criminalistas, profesionales en medicina, psiquiatría y
                      psicología forense; peritos calígrafos; bioquímicos;
                      contadores públicos; perito en balística y perito en
                      accidentología.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Servicios3;
