const menus = [  
    {
        id: 1,
        name: 'Nosotros',
        links: '/about'
    }, 
    {
        id: 2,
        name: 'Servicios',        
        links: '/service'         
    },
    {
        id: 3,
        name: 'Contacto',
        links: '/contact'
    },
   
  
    
]

export default menus;