import React from "react";

const Portfolio2 = (props) => {
  return (
    <section className="tf-section collection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h5
                className="sub-title mb-10"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Casos Públicos
              </h5>
              <h3 className="title" data-aos="fade-in" data-aos-duration="1000">
                <span>Entrevistas en televisión</span>
              </h3>
            </div>
          </div>
        </div>

        <div className="bluer">
          <div className="row">
            <div className="col-xl-4 col-md-12">
              <div className="block-text text-center">
                <p className="fs-16 mb-22">
                  Entrevista para canal 8 Tucumán. Dr Molina, Garcia Biagosh,
                  Antoniella. Caso Velez.
                </p>
                <iframe
                  width="340"
                  height="210"
                  src="https://www.youtube.com/embed/r_3EMcFImo4?si=8AYMj-S0k73E8xUm&amp;start=1"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="col-xl-4 col-md-12">
              <div className="block-text text-center">
                <p className="fs-16 mb-22">
                  En MGB, discutimos los detalles de esta causa donde defendemos
                  a los acusados.
                </p>
                <iframe
                  width="340"
                  height="210"
                  src="https://www.youtube.com/embed/HwTPUvULR5o?si=v2CJlhfYLNdw6fQe"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="col-xl-4 col-md-12">
              <div className="block-text text-center">
                <p className="fs-16 mb-22">
                  ABUSO SEXUAL EN VÉLEZ: EL LUNES DECLARA LA DENUNCIANTE
                </p>
                <iframe
                  width="340"
                  height="210"
                  src="https://www.youtube.com/embed/r2RF7QGd0Zo?si=oBScRkLUUohVwqTi"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-xl-4 col-md-12">
              <div className="block-text text-center">
                <p className="fs-16 mb-31">
                  Programa GPS POLICIALES entrevista a Los Dres Ernesto García
                  Biagosh y Antoniella Bataglia
                </p>
                <iframe
                  width="340"
                  height="210"
                  src="https://www.youtube.com/embed/EgVsvNx_vIo?si=28jSbijpbuGh5pf-&amp;start=129"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="col-xl-4 col-md-12">
              <div className="block-text text-center">
                <p className="fs-16 mb-56">
                  Vélez: el lunes Cámara Gesell a la denunciante
                </p>
                <iframe
                  width="340"
                  height="210"
                  src="https://www.youtube.com/embed/x-3P66cW-ME?si=f_n-Mv9QIEYCE06y&amp;start=571"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="col-xl-4 col-md-12">
              <div className="block-text text-center">
                <p className="fs-16 mb-31">
                  Denuncia por presunto abuso sexual: cuál es la situación de
                  los jugadores de Vélez
                </p>
                <iframe
                  width="340"
                  height="210"
                  src="https://www.youtube.com/embed/5xlaikuG7T4?si=sZ_5mHsmU1Tq_Nqw"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-xl-4 col-md-12">
              <div className="block-text text-center">
                <p className="fs-16 mb-22">
                  Entrevista al Dr. Jose Maria Molina Caso Jugadores Velez
                </p>
                <iframe
                  width="340"
                  height="210"
                  src="https://www.youtube.com/embed/nwrVIp3Wni8?si=u6Tjt4gNivdUKn2x&amp;start=129"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="col-xl-4 col-md-12">
              <div className="block-text text-center">
                <p className="fs-16 mb-22">
                  Sangre Fría - Denuncia contra jugadores de Vélez - 16/03/2024
                </p>
                <iframe
                  width="340"
                  height="210"
                  src="https://www.youtube.com/embed/vho6zv3z3iw?si=Zh2mukXtV6PQUH-2"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio2;
