import React , {useState } from 'react';
import { Link } from 'react-router-dom'


const Slider = () => {

    const [datatext] = useState({
        subtitle: 'Estudio Jurídico',
        title: 'SOMOS EXPERTOS EN DERECHO PENAL',
        desc: 'Ofrecemos servicios de representación legal en todas las etapas del proceso penal, desde la investigación inicial hasta la apelación y revisión de la sentencia.'
    })

    return (
    
    <section className="tf-section hero-slider ">
        <div className="container">
            <div className="row">
                <div className="col-xl-9 col-md-12 col-12">
                    <div className="block-text3 pt-24" >
                        <h6 className="sub-title mb-6" data-aos="fade-up">{datatext.subtitle}</h6>
                        <h2 className="title mb-26" data-aos="fade-up">{datatext.title} </h2>
                        <p className="desc mb-43" data-aos="fade-up">{datatext.desc} </p>
                        <Link to="/contact" className="btn-action style-2 border" data-aos="fade-up" data-aos-duration="1200" >Contactanos</Link>
                    </div>
                </div>
           
            </div>
        </div>
    </section>
  );
}



export default Slider;